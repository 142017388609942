// Custom.scss
// Option A: Include all of Bootstrap
// Check https://getbootstrap.com/docs/5.3/customize/sass/ for Option B (Include parts of Bootstrap) to reduce the file size of your CSS

// Include any default variable overrides here (though functions won't be available)
@import "variable-overrides.scss";

@import "bootstrap/bootstrap.scss";

// Then add additional custom code here
@import "custom.scss"; // Theme SASS
@import "styles.scss"; // Site SASS (assets/sass/styles.scss)
